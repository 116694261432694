/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
                {
          title: 'Main',
                    route: 'main',
                              icon: 'HomeIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Мои покупки',
                    route: 'orders',
                              icon: 'ShoppingCartIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Личные приглашения',
                    route: '',
                              icon: 'CornerDownRightIcon',
                            },
                {
          title: 'Структура - Дерево',
                    route: 'tree-structure',
                              icon: 'CommandIcon',
                              isBookmarked: '',
                  },
                {
          title: ' Контракты за неделю',
                    route: 'contracts',
                              icon: 'BriefcaseIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Сертификаты',
                              icon: 'BriefcaseIcon',
                            },
                {
          title: 'Финансы',
                    route: 'finance',
                              icon: 'CreditCardIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Реферальные ссылки',
                    route: '',
                              icon: 'LinkIcon',
                            },
                {
          title: 'Информация',
                              icon: 'FileTextIcon',
                            },
                {
          title: 'Профиль',
                    route: 'profile',
                              icon: 'UserIcon',
                              isBookmarked: '',
                  },
              ]
    },
  }))
}
// prettier-ignore
export default [
    {
    title: 'Main',
        route: 'main',
            icon: 'HomeIcon',
          },
    {
    title: 'Мои покупки',
        route: 'orders',
            icon: 'ShoppingCartIcon',
          },
    {
    title: 'Личные приглашения',
        route: '',
            icon: 'CornerDownRightIcon',
            children: [{"title":"\u0421\u043f\u0438\u0441\u043e\u043a","route":"personal-invitations","icon":"CircleIcon","isBookmarked":false},{"title":"\u041f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043d\u043e\u0432\u0438\u0447\u043a\u0430","route":"invite","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Структура - Дерево',
        route: 'tree-structure',
            icon: 'CommandIcon',
          },
    {
    title: ' Контракты за неделю',
        route: 'contracts',
            icon: 'BriefcaseIcon',
          },
    {
    title: 'Сертификаты',
            icon: 'BriefcaseIcon',
            children: [{"title":"\u0411\u0438\u0437\u043d\u0435\u0441-\u043f\u0430\u043a\u0438","route":"business-packs","icon":"CircleIcon","isBookmarked":false},{"title":"\u041f\u043e\u0432\u0442\u043e\u0440\u043d\u044b\u0435 \u043f\u043e\u043a\u0443\u043f\u043a\u0438","route":"repeat-purchases","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Финансы',
        route: 'finance',
            icon: 'CreditCardIcon',
          },
    {
    title: 'Реферальные ссылки',
        route: '',
            icon: 'LinkIcon',
            children: [{"title":"\u041e\u0442\u0447\u0435\u0442 \u043f\u043e \u043f\u0435\u0440\u0435\u0445\u043e\u0434\u0430\u043c","route":"navigation-report","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Информация',
            icon: 'FileTextIcon',
            children: [{"title":"\u0414\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b","route":"docs","icon":"CircleIcon","isBookmarked":false},{"title":"\u041f\u0440\u0430\u0432\u0438\u043b\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0438 ","route":"how-to-by","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Профиль',
        route: 'profile',
            icon: 'UserIcon',
          },
  ]
